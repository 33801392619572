body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* MAIN PAGE ////////////////////////////////////////////////////////////////////// */

nav {
  background-color: rgb(155, 229, 253);
  background-image: url("https://www.transparenttextures.com/patterns/cardboard-flat.png");
  padding: 10px 20px;
  border-bottom: darkblue 2px solid;
  text-align: center;
}
footer {
  background-color: rgb(155, 229, 253);
  background-image: url("https://www.transparenttextures.com/patterns/cardboard-flat.png");
  padding: 5px 20px;
  border-top: darkblue 2px solid;
  text-align: center;
}
h1 {
  font-family: fantasy;
  font-size: 3rem;
}
#page-description {
  color: rgb(51, 49, 49);
  margin: 0 auto .5rem;
  font-weight: bolder;
  font-style: italic;
  font-size: 1.1rem;
}

.main {
  flex-grow: 1;
  padding: 10px 0;
  background: url("./images/poly-bg-blue-fine.jpg") repeat;
  position: relative;
}
.test {
  border: red 1px solid;
}
.box-title {
  font-size: 1.1rem;
  font-weight: bold;
  width: 80%;
  margin: 0 auto 2.5px;
}
.box-subtitle {
  width: 80%;
  margin: 0 auto 15px;
}

/* FIRST ROW / SEPARATION OF THE PAGE /////////////////////////////////////////////////////// */
#input-and-total {
  /* border: yellow 2px solid; */
  margin: 0 auto;
  text-align: center;
}
#day-boxes{
  /* border: white 2px solid; */
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  
}
.sticky-class{
  position: sticky;
  top: 0;
}
.row {
  margin: 0;
}

/* DayBox Component  //////////////////////////////////////////////////////////////////////////// */
.day-box {
  padding: 10px 20px;
  border: solid black 2px;
  border-radius: 5px;
  box-shadow: rgb(72, 61, 139) 5px 5px;
  background-color: azure;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.dayBox-margin {
  margin: 10px auto;
}
.empty-checkbox {
  display: block;
  margin: 0 auto 5px;
}
.box-p {
  margin: 0;
}

/* InputBox Component  //////////////////////////////////////////////////////////////////////////// */
.input-box {
  padding: 10px 20px;
  margin: 10px auto;
  border: solid black 2px;
  border-radius: 5px;
  box-shadow: rgb(72, 61, 139) 5px 5px;
  background-color: azure;
  display: inline-block;
  text-align: center;
  width: 95%;
}
.inputBox-margin {
  margin: 10px auto;
}
.party-img-left {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 40px;
  left: 25px;
}
.party-img-right {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 40px;
  right: 25px;
}

/* TotalBox Component  //////////////////////////////////////////////////////////////////////////// */
.total-box {
  padding: 10px 20px;
  margin: 0 auto 10px;
  border: solid black 2px;
  border-radius: 5px;
  box-shadow: rgb(72, 61, 139) 5px 5px;
  background-color: azure;
  display: inline-block;
  text-align: center;
  width: 95%;
}
.total-result{
  font-size: 2rem;
  font-family: cursive;
  padding: 0;
  margin: 2.5px 2.5px 0;
}
.total-description {
  margin: -3px auto 0;
  line-height: 1;
  font-style: italic;
}
.total-container {
  margin: 7px auto;
}

/* PopUpReloaded-Component  ///////////////////////////////////////////////////////////// */
.popUp-overlay {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black fallback color */
  overflow-x: hidden; /* Disable horizontal scroll */
}
.popUp-content {
  position: relative;
  top: 30%; /* 50% from the top */
  background-color: azure;
  background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
  width: 40%;
  padding: 20px 10px;
  margin: 0 auto;
  z-index: 5;
  border: solid black 2px;
  border-radius: 5px;
  box-shadow: rgb(42, 36, 80) 5px 5px;
  text-align: center;
}
.close-popUp-btn {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding-left: 80%;
  color: rgb(23, 23, 133);
  position: relative;
  top: -15px;
}

/* Hooray-Component  ///////////////////////////////////////////////////////////// */
.hooray-content {
  position: relative;
  top: 15%; /* 50% from the top */
  background-color: #d89cf6;
  background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
  width: 50%;
  padding: 20px 10px;
  margin: 0 auto;
  z-index: 5;
  border: solid black 2px;
  border-radius: 5px;
  box-shadow: rgb(42, 36, 80) 5px 5px;
  text-align: center;
}
.hooray-img {
  max-width: 400px;
  max-height: 300px;
  padding-bottom: 10px;
}
.hooray-title {
  font-size: 1.9rem;
  padding-bottom: 15px;
  font-weight: bold;
  margin-top: -15px;
}

/* ///////////////////////////////////////   MEDIA QUERIES   ////////////////////////////////// */

/********** Large devices only **********/
@media (min-width: 1200px) {
  #input-and-total {
    padding-left: 30px;
    width: 90%;
  }
  .input-title {
    width: 85%;
  }

  /* Adjusting the display of partyHatImages */
  .party-img-left {
    position: absolute;
    top: 25px;
    left: 25px;
  }
  .party-img-right {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  /* Adjusting position of PopUpClose-button */
  .close-popUp-btn {
    right: -25px;
  }
}

/********** Medium devices only **********/
@media (min-width: 992px) and (max-width: 1199px) {
  #input-and-total {
    padding-left: 25px;
    width: 90%;
  }

  /* Adjusting position of PopUpClose-button */
  .close-popUp-btn {
    right: -25px;
  }
}

/********** Small devices only **********/
@media (min-width: 768px) and (max-width: 991px) {
  #input-and-total {
    /* border-bottom: white 2px dotted; */
    padding: 0px 15px 25px;
    width: 95%;
  }

  /* Adjusting the display of partyHatImages */
  .party-img-left {
    visibility: hidden;
  }
  .party-img-right {
    visibility: hidden;
  }
}

  /* Adjusting width of total-displays */
  total-row{
    width: 80%;
  }

/********** Extra small devices only **********/
@media (min-width: 576px) and (max-width: 767px) {
  #input-and-total {
    border-bottom: white 2px dotted;
    padding: 0px 15px 15px;
    width: 95%;
  }
  #day-boxes{
    /* border: white 2px solid; */
    padding: 15px 15px 0px;
  }
  .hooray-img {
    max-width: 200px;
    max-height: 200px;
  }
}
/* For better adaption of the dayBoxes on small screen */
@media (max-width: 575px) {
  #input-and-total {
    border-bottom: white 2px dotted;
    padding: 0px 15px 15px;
    width: 95%;
  }
  #day-boxes{
    /* border: white 2px solid; */
    padding: 15px 15px 0px;
    width: 80%;
  }
  .hooray-img {
    max-width: 100px;
    max-height: 100px;
  }
}